
export class LogBookColumn {
  constructor(
    public id: number,
    public name: string,
    public isNumeric: boolean
  ) {
  }
}

export class LogBook {
  constructor(
    public id: number,
    public name: string,
    public columns: LogBookColumn[]
  ) {
  }
}

export function makeLogBookColumn(jsonData: any = null): LogBookColumn {
  if (!jsonData) {
    jsonData = {}
  }
  return new LogBookColumn(
    jsonData.id || 0,
    jsonData.name || '',
    !!jsonData.is_numeric
  )
}

export function makeLogBook(jsonData: any = null): LogBook {
  if (!jsonData) {
    jsonData = {}
  }
  const columns = jsonData.columns || []
  return new LogBook(
    jsonData.id || 0,
    jsonData.name || '',
    columns.map(makeLogBookColumn)
  )
}

export class LogBookValue {
  constructor(
    public id: number,
    public column: number,
    public value: string
  ) {
  }
}

export class LogBookItem {
  constructor(
    public id: number,
    public createdOn: Date,
    public createdBy: string,
    public text: string,
    public values: LogBookValue[]
  ) {
  }
}

export function makeLogBookValue(jsonData: any = null): LogBookValue {
  if (!jsonData) {
    jsonData = {}
  }
  return new LogBookValue(
    jsonData.id || 0,
    jsonData.column || 0,
    jsonData.value || ''
  )
}

export function makeLogBookItem(jsonData: any = null): LogBookItem {
  if (!jsonData) {
    jsonData = {}
  }
  const values = jsonData.values || []
  return new LogBookItem(
    jsonData.id || 0,
    new Date(jsonData.created_on),
    jsonData.created_by || '',
    jsonData.text || '',
    values.map(makeLogBookValue)
  )
}
