<template>
  <span class="add-logbook-item-modal" v-if="hasPerm('log_books.add_logbookitem')">
    <b-modal
      id="bv-modal-add-logbook-item"
      cancel-title="Annuler"
      :ok-disabled="!isValid()"
      @ok.prevent="onAdd"
      ok-variant="primary"
      ok-title="Ajouter"
      @show="onShow"
      size="lg"
    >
      <template v-slot:modal-title>
        <b>
          <i class="fa fa-ship"></i>
          <span v-if="logBook">{{ logBook.name }}</span>
        </b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row v-for="col of logBook.columns" :key="col.id">
        <b-col>
          <b-form-group
            :id="'col-group' + col.id"
            :label="col.name"
            :label-for="'col' + col.id"
          >
            <div v-if="col.isNumeric">
              <decimal-input required :id="'col' + col.id" v-model="data['col' + col.id]">
              </decimal-input>
            </div>
            <div v-else>
              <b-input type="text" required :id="'col' + col.id" v-model="data['col' + col.id]">
              </b-input>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="text-gr"
            label="Commentaires"
            label-for="text"
          >
            <b-form-textarea id="text" v-model="text"></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { mapActions } from 'vuex'
import DecimalInput from '@/components/Controls/DecimalInput'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'

export default {
  name: 'add-logbook-item-modal',
  components: { DecimalInput, },
  mixins: [BackendMixin],
  props: {
    logBook: Object,
  },
  data() {
    return {
      data: {},
      errorText: '',
      text: '',
    }
  },
  computed: {
  },
  mounted() {
  },
  watch: {
  },
  methods: {
    ...mapActions(['addSuccess']),
    isValid() {
      for (const col of this.logBook.columns) {
        if (this.data['col' + col.id] === '') {
          return false
        }
      }
      if ((this.logBook.columns.length === 0) && !this.text) {
        return false
      }
      return true
    },
    async onAdd() {
      this.errorText = ''
      try {
        const url = '/log-books/api/log-books/' + this.logBook.id + '/'
        const backendApi = new BackendApi('post', url)
        const valuesData = []
        for (const col of this.logBook.columns) {
          valuesData.push(
            { column: col.id, value: this.data['col' + col.id], }
          )
        }
        const data = {
          text: this.text,
          values: valuesData,
        }
        await backendApi.callApi(data)
        this.$bvModal.hide('bv-modal-add-logbook-item')
        this.$emit('done')
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    onShow() {
      for (const col of this.logBook.columns) {
        if (col.isNumeric) {
          this.data['col' + col.id] = 0
        } else {
          this.data['col' + col.id] = ''
        }
      }
      this.text = ''
      this.data = { ...this.data, }
    },
  },
}
</script>
<style lang="less">
</style>
